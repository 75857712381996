import React, { Component } from "react";
import {Stitch, AnonymousCredential, RemoteMongoClient} from "mongodb-stitch-browser-sdk";
import { HttpServiceClient, HttpRequest, HttpMethod } from "mongodb-stitch-browser-services-http";
import { EJSON } from "bson"; // needed to parse Mongo service response

import { Navbar, Alert, Container, Card, Row, Col, Form, InputGroup, FormControl, ListGroup, Button } from "react-bootstrap";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

class App extends Component {
  constructor() {
    super();
    this.state = {
      shipments: [],
      value: "",
      entrystatus: "",
      locationstatus: "",
      location: {
        latitude: 0,
        longitude: 0
      }
    };
    this.handleChange = this.handleChange.bind(this);
    this.displayShipments = this.displayShipments.bind(this);
    this.addShipment = this.addShipment.bind(this);
    this.deleteShipment = this.deleteShipment.bind(this);
    this.sendLocation = this.sendLocation.bind(this);
    this.getLocation = this.getLocation.bind(this);
    this.backgroundLoad = this.backgroundLoad.bind(this);
  }
  // initialize app after mounting
  componentDidMount() {
    // Initialize the App Client
    this.client = Stitch.initializeDefaultAppClient("stitchappfreight-spyoy");
    // Get a MongoDB Service Client for HTTP calls
    // This is used for routing WS calls through Stitch 
    this.mongohttp = this.client.getServiceClient(HttpServiceClient.factory, "location");
    // Get a MongoDB Service Client
    // This is used for logging in and communicating with Stitch
    const mongodb = this.client.getServiceClient(RemoteMongoClient.factory, "mongodb-atlas");
    // Get a reference to the freight database
    this.db = mongodb.db("freight");

    this.getLocation();
    this.displayShipmentsOnLoad();

    // use setinterval to update location in the background
    setInterval(() => {
      this.backgroundLoad()
    }, 150000);

    console.log(`${process.env.REACT_APP_NAME} ${process.env.REACT_APP_VERSION}`);
  }

  async backgroundLoad() {
    console.log("run background load", new Date().toLocaleTimeString(), this.state.shipments);
    try {
      if(this.state.location.latitude !== 0) {
        this.state.shipments.forEach(async (shipment) => {
          let req = new HttpRequest.Builder()
            .withMethod(HttpMethod.POST)
            .withUrl("https://ws.pacificgroup.net/restapi/index.cfm/borderpro/location")
            .withHeaders({ "Content-Type": ["application/json"] })
            .withEncodeBodyAsJson(true)
            .withBody({
              cargonum: shipment.cargonum,
              lat: this.state.location.latitude.toString(),
              long: this.state.location.longitude.toString(),
              bg:true, returnlocations:false
            })
            .build();
          // execute the http request to Stitch service
          await this.mongohttp.execute(req)
            .then((resp) => {
              console.log(resp);
              this.setState({ locationstatus: "Location sent " + new Date().toLocaleTimeString() + ' (bg)'});
            });
        });
      } else {
        console.log('No location to send yet.');
      }
    } catch (e) {
      console.log('background load error',e);
    }
  }
  
  displayShipments() {
    // query the remote DB and update the component state
    this.db
      .collection("item")
      .find({}, { limit: 1000 })
      .asArray()
      .then(shipments => {
        this.setState({shipments});
      });
  }
  displayShipmentsOnLoad() {
    // Anonymously log in and display comments on load
    this.client.auth
      .loginWithCredential(new AnonymousCredential())
      .then(this.displayShipments)
      .catch(console.error);
  }
  addShipment(event) {
    event.preventDefault();
    const { value } = this.state;
    this.setState({ entrystatus: "" });

    if(value.length === 0) {
      this.setState({ entrystatus: "Empty tracking number" });
      return true;
    }

    // validate the shipment
    const req = new HttpRequest.Builder()
      .withMethod(HttpMethod.GET)
      .withUrl("https://ws.pacificgroup.net/restapi/index.cfm/borderpro/location")
      .withHeaders({ "Content-Type": ["application/json"] })
      .withEncodeBodyAsJson(true)
      .withBody({ cargonum: value.toUpperCase() })
      .build();
    // execute the http request to Stitch service
    this.mongohttp.execute(req)
      .then((resp) => {
        if(resp.statusCode === 404) {
          this.setState({ entrystatus: "Invalid tracking number" });
        } else {
          // insert the shipment into the remote Stitch DB
          // then re-query the DB and display the new shipments
          this.db
          .collection("item")
          .insertOne({
            owner_id: this.client.auth.user.id,
            cargonum: value.toUpperCase()
          })
          .then(this.displayShipments)
          .then(this.setState({ value: "" }))
          .catch(console.error);
        }
      })
      .catch((err) => {
        this.setState({ entrystatus: "Invalid tracking number" });
        console.error(err.message);
      });
 }
  deleteShipment(cargonum) {
    //let filteredArray = this.state.shipments.filter(shipment => shipment.cargonum !== key);
    //this.setState({shipments: filteredArray});

    // insert the shipment into the remote Stitch DB
    // then re-query the DB and display the new shipments
    this.db
      .collection("item")
      .deleteOne({cargonum: cargonum})
      .then(this.displayShipments)
      .catch(console.error);
  }
  sendLocation() {
    console.log('sending location');
    // initialize lat/long
    let latitude = "0";
    let longitude = "0";
    // get the latest lat/long
    this.getLocation()
      .then((position) => {
        // set the lat/long
        latitude = position.coords.latitude;
        longitude = position.coords.longitude;
        console.log('got location',latitude.toString(),longitude.toString());
        this.setState({"location": position.coords});
        return true;
      })
      .then(() => {
        // loop over all registered shipments
        this.state.shipments.forEach((shipment) => {
          console.log('sending location',latitude.toString(),longitude.toString());
          // build a Mongo Stitch web request
          const req = new HttpRequest.Builder()
            .withMethod(HttpMethod.POST)
            .withUrl("https://ws.pacificgroup.net/restapi/index.cfm/borderpro/location")
            .withHeaders({ "Content-Type": ["application/json"] })
            .withEncodeBodyAsJson(true)
            .withBody({ cargonum: shipment.cargonum, lat: latitude.toString(), long: longitude.toString() })
            .build();
          // execute the http request to Stitch service
          this.mongohttp.execute(req)
            .then((resp) => {
              // we should get a binary response from Stitch
              let binaryResponse = EJSON.deserialize(resp.body); //stringify the object first
              // convert that binary response to a JSON string
              let jsonResponse = atob(binaryResponse)
              // parse that JSON string
              let objResponse = JSON.parse(jsonResponse);       
              // return it to continue the processing
              return objResponse;
            }).then((data) => {
              console.log('Retrieved location data from REST', data.length, 'records');
              this.setState({ locationstatus: "Location sent " + new Date().toLocaleTimeString() });
              return data;
            })
            .catch((err) => {
              console.log('stitch http response err',err)
              this.setState({ locationstatus: "Unable to share location" });
            });
        });
      })
      .catch((err) => {
        console.error(err.message);
      });
    
  }
  // promise version of geolocation.getCurrentPosition
  getLocation = function (options) {
    return new Promise(function (resolve, reject) {
      navigator.geolocation.getCurrentPosition(resolve, reject, options);
    });
  }
  handleChange(event) {
    this.setState({ value: event.target.value });
  }
render() {
    return (
      <div className="App">
        <Navbar bg="primary" variant="dark">
          <Navbar.Brand className="mr-auto">
            <img alt=""
              src="https://uploads-ssl.webflow.com/5c7da11f03dd897c90e6ef71/5c7da11f03dd892cc4e6f061_PCB-logo-new-134x64.png"
              height="32"
              className="d-inline-block align-top"
            />
          </Navbar.Brand>
          <Navbar.Text className="mr-sm-2">
            Register and track shipments
          </Navbar.Text>
        </Navbar>
        <Container fluid>
          <Card>
            <Card.Body>
              <Card.Subtitle>Register one or more shipments to track</Card.Subtitle>
              <Form onSubmit={this.addShipment}>
                <Row>
                  <InputGroup>
                    <FormControl type="text"
                      placeholder="Enter Cargo Number"
                      value={this.state.value} onChange={this.handleChange} />
                  </InputGroup>
                </Row>
                {
                  (this.state.entrystatus.length > 0) &&
                  <Row><Alert variant="danger" id="entrystatus" className="w-100">{this.state.entrystatus}</Alert></Row>
                }
                <div className="mt-3" />
                <Row>
                  <Col xs="8">
                    <Button type="submit" block>Register for tracking</Button>
                  </Col>
                  <Col xs="4">
                    {/* <Button block>Scan</Button> */}
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
          <div className="mt-3" />
          <Card>
            <Card.Body>
              <Row>
                <Col xs="4">
                  <Button onClick={this.sendLocation} block>Send Location</Button>
                </Col>
                <Col xs="8">
                  <span id="locationstatus">{this.state.locationstatus}</span>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <div className="mt-3" />
          <Card>
            <Card.Body>
              {
                (this.state.shipments.length > 0) &&
                <Card.Subtitle>These are the shipments you are tracking</Card.Subtitle>
              }
            </Card.Body>
            <ListGroup>
              {/* Map over the shipments from our remote DB */}
              {this.state.shipments.map(shipment => {
                return <ListGroup.Item key={shipment.cargonum}>{shipment.cargonum} <Button variant="secondary" onClick={() => this.deleteShipment(shipment.cargonum)}>Unregister</Button></ListGroup.Item>;
              })}
            </ListGroup>
          </Card>
        </Container>
        <Navbar sticky="bottom">
          <Navbar.Text className="ml-auto">
            <small>
              &copy; PCB {(new Date().getFullYear())} {''}
              {process.env.REACT_APP_NAME} v.{process.env.REACT_APP_VERSION}
            </small>
          </Navbar.Text>
        </Navbar>
      </div>
    );
  }
}
export default App;